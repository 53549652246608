import ContactsToricMultifocalL from './ContactsToricMultifocalL'
import ContactsMultifocalL from './ContactsMultifocalL'
import ContactsToricL from './ContactsToricL'
import ContactsSphericalL from './ContactsSphericalL'

export default function Contacts(props) {
  const {
    cyl = 0,
    power = 0,
    axis = 0,
    vertexDistance = 0,
    addPower = 0,
    monovision = false,
    contactPicked = {},
  } = props;

  const minusCheck = isNaN(cyl * 1) || cyl === "sphere" || cyl === "sph" || cyl * 1 === 0 ? 0 : cyl * 1 < 0;

  const powerCheck =
    (power === "plano" || power === "pl") && (cyl === "sphere" || cyl === "sph")
      ? 0
      : (power === "plano" || power === "pl") && cyl > 0
      ? cyl * 1
      : (power === "plano" || power === "pl") && cyl < 0
      ? 0
      : minusCheck === false
      ? power * 1 + cyl * 1
      : power * 1;

  const cylCheck = cyl === "sphere" || cyl === "sph" ? 0 : cyl * 1 > 0 ? cyl * -1 : cyl * 1;

  const axisCheck = axis === "" ? "" : minusCheck === false ? (axis * 1 > 0 && axis <= 90 ? axis * 1 + 90 : axis * 1 - 90) : axis * 1;

  //_____________________________________________________________________________

  const vertexPower = powerCheck / (1 - vertexDistance * 0.001 * powerCheck);
  const vertexCyl = cylCheck / (1 - vertexDistance * 0.001 * cylCheck);
  const sphEqPower = vertexPower + 0.5 * vertexCyl;


  const halfStepsPlus = contactPicked?.halfStepsPlus === ''
  ? contactPicked?.powerMax
  : contactPicked?.halfStepsPlus;


  const halfStepsMinus = contactPicked?.halfStepsMinus === ''
  ? contactPicked?.powerMin
  : contactPicked?.halfStepsMinus;

  // console.log(halfStepsMinus)
  // console.log(halfStepsPlus)

  const roundPower = 
    vertexPower < halfStepsMinus 
    || 
    vertexPower > halfStepsPlus
    || 
    sphEqPower < halfStepsMinus
    || 
    sphEqPower > halfStepsPlus
    ? contactPicked?.type === 'toric' && monovision
      ? Math.round(vertexPower / 0.5) * 0.5 + addPower * 1 - 0.5
      : contactPicked?.type === 'spherical' && monovision
      ? Math.round(sphEqPower / 0.5) * 0.5 + addPower * 1 - 0.5
      : contactPicked?.type === 'spherical' || contactPicked?.type === 'multifocal'
      ? Math.round(sphEqPower / 0.5) * 0.5
      : Math.round(vertexPower / 0.5) * 0.5
    : contactPicked?.type === 'toric' && monovision
    ? Math.round(vertexPower / 0.25) * 0.25 + addPower * 1 - 0.25
    : contactPicked?.type === 'spherical' && monovision
    ? Math.round(sphEqPower / 0.25) * 0.25 + addPower * 1 - 0.25
    : contactPicked?.type === 'spherical' || contactPicked?.type === 'multifocal'
    ? Math.round(sphEqPower / 0.25) * 0.25
    : Math.round(vertexPower / 0.25) * 0.25;

    const roundCyl = minusCheck === 0 
    ? 0 
    : Math.abs(cylCheck - vertexCyl) < 0.25 
      ? Math.round(cylCheck / 0.25) % 2 === 0 
        ? (Math.round(cylCheck / 0.25) + 1) * 0.25 
        : Math.round(cylCheck / 0.25) * 0.25 
      : Math.round(vertexCyl / 0.25) % 2 === 0 
        ? (Math.round(vertexCyl / 0.25) + 1) * 0.25 
        : Math.round(vertexCyl / 0.25) * 0.25;
   
   // if vertexCyl is within 0.25 of actual cylinder, then use actual cyilnder

  const pBetween = contactPicked?.cyl?.filter(
    (c) => roundPower >= c.pMin * 1 && roundPower <= c.pMax * 1
  ) || [];

  const pHigh = contactPicked?.cyl?.some((c) => roundPower >= c.pMin * 1 && roundPower >= c.pMax * 1)
    ? contactPicked?.powerMax * 1
    : '';

  const pLow = contactPicked?.cyl?.some((c) => roundPower <= c.pMin * 1 && roundPower <= c.pMax * 1)
    ? contactPicked?.powerMin * 1
    : '';

  const pPicked = contactPicked?.type === 'spherical' || contactPicked?.type === 'multifocal'
    ? roundPower === 0
      ? 0
      : roundPower > contactPicked?.powerMax * 1
      ? contactPicked?.powerMax * 1
      : roundPower < contactPicked?.powerMin * 1
      ? contactPicked?.powerMin * 1
      : roundPower
    : pBetween.length >= 1
    ? roundPower
    : pHigh === ''
    ? pLow
    : pHigh;

  const cBetween = contactPicked?.cyl?.filter(
    (c) =>
      pPicked >= c.pMin * 1 &&
      pPicked <= c.pMax * 1 &&
      roundCyl <= c.cMax * 1 &&
      roundCyl >= c.cMin * 1
  ) || [];

  let cLow = contactPicked?.cyl?.filter(c =>
    pPicked >= c.pMin*1 &&
    pPicked <= c.pMax*1 &&
    roundCyl <= c.cMin*1
  ) || [];

  let cMinArray = cLow.map(c => c.cMin*1) || [];

  let cPicked = cBetween.length > 0
  ? roundCyl
  : cMinArray.length > 0
    ? Math.min(...cMinArray)
    : '';

  const aFind = contactPicked?.cyl?.find(
    (c) =>
      c.pMin * 1 <= pPicked &&
      c.pMax * 1 >= pPicked &&
      c.cMax * 1 >= cPicked &&
      c.cMin * 1 <= cPicked
  );

  const aMatch =
    cPicked !== '' && aFind && axisCheck >= 1 && axisCheck <= 180
      ? 'x' + aFind.degrees.reduce((prev, curr) =>
          Math.abs(curr - axisCheck) < Math.abs(prev - axisCheck) ? curr : prev
        )
      : '';

  const powerWarning =
    (contactPicked?.type === 'spherical' || contactPicked?.type === 'multifocal') &&
    (roundPower > contactPicked?.powerMax * 1 || roundPower < contactPicked?.powerMin * 1)
      ? `WARNING: power needed is ${roundPower > 0 ? '+' : ''}${roundPower.toFixed(2)}`
      : '';

  const cylWarning = cBetween.length === 0 ? `WARNING: cylinder needed is ${roundCyl.toFixed(2)}` : '';

  let add4Step = ((contactPicked && contactPicked === "") || contactPicked?.add === "")
  ? 'no add needed'
  : (addPower*1 <= 1.25)
    ? (contactPicked?.add?.value1)
    : (addPower*1 >= 1.50 && addPower*1 <= 1.75)
      ? (contactPicked?.add?.value2)
      : (addPower*1 >= 2.00 && addPower*1 <= 2.25)
        ? (contactPicked?.add?.value3)
        : (addPower*1 >= 2.50)
          ? (contactPicked?.add?.value4)
          : '';
  
  
  let add3Step = ((contactPicked && contactPicked === "") || contactPicked?.add === "")
    ? 'no add needed'
    : (addPower*1 <= 1.50)
      ? (contactPicked?.add?.value1)
      : (addPower*1 >= 1.75 && addPower*1 <= 2.00)
        ? (contactPicked?.add?.value2)
        : (addPower*1 >= 2.25)
          ? (contactPicked?.add?.value3)
          : '';
  
  let add2Step = ((contactPicked && contactPicked === "") || contactPicked?.add === "")
    ? 'no add needed'
    : (addPower*1 <= 1.75)
      ? (contactPicked?.add?.value1)
      : (addPower*1 >= 2.00)
        ? (contactPicked?.add?.value2)
        : '';
  
  let add1Step = ((contactPicked && contactPicked === "") || contactPicked?.add === "")
    ? 'no add needed'
    : (contactPicked?.add?.value1)

//____________________________________________________________________________


  const finalPower = (pPicked === 0 || pPicked === -0)
  ? 'Plano'
  : typeof pPicked === 'number'
    ? (pPicked > 0 ? `+${pPicked.toFixed(2)}` : pPicked.toFixed(2))
    : 'Check power';

  
  const finalCyl = cPicked === '' || cPicked === 0 || cPicked === -0 ? 'sphere' : typeof cPicked === 'number' ? cPicked.toFixed(2) : 'sphere';

  let finalAdd = contactPicked?.add?.addStep*1 === 4
    ? add4Step
    : contactPicked?.add?.addStep*1 === 3
      ? add3Step
      : contactPicked?.add?.addStep*1 === 2
        ? add2Step
        : add1Step;


  return (
    <div>
      {contactPicked?.type === 'toricMultifocal' && (
        <ContactsToricMultifocalL
          power={finalPower}
          cyl={finalCyl}
          axis={aMatch}
          addPower={addPower}
          add={finalAdd}
          powerWarning={powerWarning}
          cylWarning={cylWarning}
          contactPicked={contactPicked}
        />
      )}
      {contactPicked?.type === 'multifocal' && (
        <ContactsMultifocalL
          power={finalPower}
          addPower={addPower}
          add={finalAdd}
          powerWarning={powerWarning}
          contactPicked={contactPicked}
        />
      )}
      {contactPicked?.type === 'toric' && (
        <ContactsToricL
          power={finalPower}
          monovision={monovision}
          cyl={finalCyl}
          axis={aMatch}
          addPower={addPower}
          powerWarning={powerWarning}
          cylWarning={cylWarning}
          contactPicked={contactPicked}
        />
      )}
      {contactPicked?.type === 'spherical' && (
        <ContactsSphericalL
          power={finalPower}
          monovision={monovision}
          addPower={addPower}
          powerWarning={powerWarning}
          contactPicked={contactPicked}
        />
      )}
    </div>
  );
}
